
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91claimLine_93gxixEUNrYA9poMHurv5VHxO0cRHG32jWNvDIjX7x4noMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue?macro=true";
import { default as indexlVU87M_456YnrAfMD2ZX5FUIij2X3F5Djl0tcywU9GppYMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue?macro=true";
import { default as _91claimId_93BqrUxBJmncvmAbrlPMnS4Q4nQ99upfzbFhn_KyHf0HkMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue?macro=true";
import { default as _91rid_93Ic1_45L3pj0PyUnmaFrLfQ2asxT_45aWTSHpOMyVHFDv2J8Meta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue?macro=true";
import { default as _91connectorId_93pBb8NfODzgcz2VGlxGi0DRKkKmFu8lW9LrXy88fSbnYMeta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as app_45statusdrWaKx6D1ZElz21ZRH93P6_34FiF5zFf2cDU3dN71cEMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/app-status.vue?macro=true";
import { default as indexiXCIaZWErF8vALSGmbpYBJvev5A_eyzr63Oh_45h6CC9wMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/index.vue?macro=true";
import { default as auth_45callbackEjVGi_328R450UGPBHL7gKxgGlePcBkSoCYV3Rd6AEAMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginektrxvC9NpudrdnB5U5wJtROq8hU5l0UajxzXe_45b5lAMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusrX_Dpd_45iSI_FiKI_ZqylUrWR_45z_45mL5fHdtiX3WOE56QMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: _91claimId_93BqrUxBJmncvmAbrlPMnS4Q4nQ99upfzbFhn_KyHf0HkMeta?.name,
    path: ":claimId()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue"),
    children: [
  {
    name: "connectorId-claimId-claimLine",
    path: ":claimLine()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue")
  },
  {
    name: "connectorId-claimId",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue")
  }
]
  },
  {
    name: "connectorId-claim-lab-claimId-rid",
    path: "claim-lab/:claimId()/:rid()",
    meta: _91rid_93Ic1_45L3pj0PyUnmaFrLfQ2asxT_45aWTSHpOMyVHFDv2J8Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue")
  }
]
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45statusdrWaKx6D1ZElz21ZRH93P6_34FiF5zFf2cDU3dN71cEMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/app-status.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/index.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbackEjVGi_328R450UGPBHL7gKxgGlePcBkSoCYV3Rd6AEAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginektrxvC9NpudrdnB5U5wJtROq8hU5l0UajxzXe_45b5lAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statusrX_Dpd_45iSI_FiKI_ZqylUrWR_45z_45mL5fHdtiX3WOE56QMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]